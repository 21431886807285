import React from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { FiChevronDown } from 'react-icons/fi'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import SEO from 'components/elements/SEO/SEO'
import GDPRPopup from 'components/modules/GDPRPopup/GDPRPopup'
import Carousel from 'components/modules/Carousel/Carousel'

const HomeTemplate = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('Home')} />
      <GDPRPopup />
      <div className="center-element h-padding discover">
        <Carousel />

        <AniLink
          className="go-to-button pompeii-red-color"
          direction="up"
          cover
          bg="#A22E37"
          duration={1}
          to="/about-us"
        >
          <p>
            <Trans>{t('aboutUs')}</Trans>
          </p>
          <FiChevronDown className="chevron" />
        </AniLink>
      </div>
    </>
  )
}
export default HomeTemplate
