import React from 'react'
import ButtonMUI from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    '&.MuiButton-root': {
      backgroundColor: `#DF9D3D`,
      borderRadius: `0px`,
      color: `white`,
      textTransform: `none`,
      fontFamily: `rubik-regular`,
    },
  },
}))

export default function Button({ cssClass, children, variant, ...rest }) {
  const classes = useStyles()

  return (
    <ButtonMUI className={`${classes.root} ${cssClass}`} {...rest}>
      {children}
    </ButtonMUI>
  )
}

Button.defaultProps = {
  children: undefined,
  variant: undefined,
}
