import React from 'react'
import Slider from 'react-slick'
import CustomImage from 'components/foundations/Image/CustomImage'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import 'style/slick.scss'
import 'style/slick-theme.scss'

const CarouselSlider = () => {
  const MenuLinks = [
    {
      name: 'bookingTemplate.pompei.place',
      link: 'tours/small-group/pompeii/',
      id: 'pompei',
      img: 'slide/pompeii.jpg',
    },
    {
      name: 'bookingTemplate.ercolano.place',
      link: 'tours/small-group/herculaneum/',
      id: 'ercolano',
      img: 'slide/herculaneum.jpg',
    },
    { name: 'bookingTemplate.capri.place', link: '/', id: 'capri', img: 'slide/capri.jpg' },
  ]

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          autoplay: false,
          autoplaySpeed: 4000,
          slidesToShow: 1,
        },
      },
    ],
  }

  const { t } = useTranslation()

  return (
    <Slider {...settings}>
      {MenuLinks.map(link => (
        <div className="carousel-home" key={link.id}>
          <h4 className="carousel-text pompeii-red-color">
            <Trans>{link.name}</Trans>
          </h4>
          <CustomImage filename={link.img} className="tour-fill-img-container" />
          <div className="div-btn-carousel">
            <AniLink className="link-carousel more-button" paintDrip hex="#ffffff" duration={0.8} to={link.link}>
              {link.id !== 'capri' ? <Trans>{t('findOutMore')}</Trans> : <Trans>{t('comingSoon')}</Trans>}
            </AniLink>
          </div>
        </div>
      ))}
    </Slider>
  )
}
export default CarouselSlider
