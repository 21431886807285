/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-parens */
/* eslint-disable prettier/prettier */
import React from 'react';
import CookieBanner from './components/CookieBanner';
import { isServer } from './helpers';

const CookieBannerUniversal = props => (isServer() ? null : <CookieBanner {...props} />);

export { CookieBannerUniversal as CookieBanner };
