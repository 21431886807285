/* eslint-disable no-undef */
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Button from 'components/foundations/Button/Button'
import { IoMdSettings } from 'react-icons/io'
import { CookieBanner } from '../../../services/react-cookie-law/src'

const removeCookies = () => {
  document.cookie = 'rcl_consent_given= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  document.cookie = 'rcl_preferences_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  document.cookie = 'rcl_statistics_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  document.cookie = 'rcl_marketing_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  window.location.reload()
}
const GDPRPopup = () => {
  const { t } = useTranslation()
  return (
    <>
      <CookieBanner
        policyLink="/privacy-policy/"
        privacyPolicyLinkText="Privacy & Cookie Policy"
        message={t('GDPRPopup.message')}
        necessaryOptionText={t('GDPRPopup.necessary')}
        preferencesOptionText={t('GDPRPopup.preferences')}
        statisticsOptionText={t('GDPRPopup.statistics')}
        marketingOptionText={t('GDPRPopup.marketing')}
        acceptButtonText={t('GDPRPopup.accept')}
        acceptAllButtonText={t('GDPRPopup.acceptAll')}
        declineButtonText={t('GDPRPopup.decline')}
        optionsButtonText={t('GDPRPopup.options')}
        showDeclineButton
        dismissOnScroll={false}
        showPreferencesOption={false}
        showStatisticsOption
        showMarketingOption
        style={{ backgroundColor: '#a32d37' }}
      />
      <Button onClick={removeCookies} className="cookies-settings">
        <IoMdSettings className="setting-icon" />
      </Button>
    </>
  )
}
export default GDPRPopup
